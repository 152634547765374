import { render, staticRenderFns } from "./flow.vue?vue&type=template&id=5393b5dc&scoped=true"
import script from "./flow.vue?vue&type=script&lang=js"
export * from "./flow.vue?vue&type=script&lang=js"
import style0 from "./flow.vue?vue&type=style&index=0&id=5393b5dc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5393b5dc",
  null
  
)

export default component.exports