<template>
  <div style="text-align: center;">

    <h1 class="big_title" style="margin-top:30px;margin-bottom:17px">入会申请</h1>
    <div class="box1">
    <div class="left_btn">
<!--      <a href="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldFiles/dlc/%E5%A4%A7%E8%BF%9E%E5%B8%82%E4%BB%93%E5%82%A8%E5%8D%8F%E4%BC%9A%E5%85%A5%E4%BC%9A%E7%94%B3%E8%AF%B7%E8%A1%A8.zip">下载入会申请表</a>-->
      <a href="#">下载入会申请表</a>
    </div>
      <div style="width:200px;padding:0 20px"><el-divider direction="horizontal"></el-divider></div>
      <div class="right_btn" @click="handleOpen">上传附件</div>
    </div>
    <h1 class="big_title"  style="margin-top:55px;margin-bottom:22px">入会流程</h1>
    <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/LC.png" alt="">

    <h1 class="big_title"  style="margin-top:55px;margin-bottom:22px">入会指南</h1>
    <div>
      <div v-for="(item,i) in ruhui_lc" :key='i' class="lc_item">
        <span>{{ item.tit }}</span>
        <p>{{ item.msg }}</p>
      </div>
    </div>
    <div class="footerBox">
      <div>
        <h1 class="big_title">会员权益</h1>
        <p>1、有本会选举权、被选举权和表决权</p>
        <p>2、对本会的工作提出建议，进行批评、监督</p>
        <p>3、优先得到协会的资料及提供的服务</p>
        <p>4、优先参加协会组织的有关活动</p>
      </div>
      <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/ship1.png" alt="">
    </div>
    <el-dialog title="上传附件" width="900px" :visible.sync="dialogTableVisible">
        <div class="dialogConten">
            <div class="sts_success" v-if="upSuccess">上传成功！</div>
            <div class="sts_error" v-if="(fileCheck&&!file_1)||(fileCheck&&!file_2)||(fileCheck&&!file_3)">请上传附件！</div>
            <div class="upLoadBox">
                <fileUpload
                    :accept="fileUploadOptions.accept"
                    :limitSize="fileUploadOptions.limitSize"
                    :autoUpload="fileUploadOptions.autoUpload"
                   @uploadCompleteOSS="uploadCompleteOSS1">
                    <div class="item" >
                      <div style="margin-bottom:13px" v-if="file_1 || fileCheck">
                        <i v-if="file_1" class="el-icon-success" style="color:#48d17b;font-size: 24px;"></i>
                        <i v-if="fileCheck&&!file_1" class="el-icon-error" style="color:#FA5151;font-size: 24px;"></i>
                      </div>
                      <div>
                        <i class="el-icon-upload2"></i>
                        <span>选择入会申请表上传（须盖章）</span>
                      </div>
                    </div>
                </fileUpload>
                <fileUpload
                    :accept="fileUploadOptions.accept"
                    :limitSize="fileUploadOptions.limitSize"
                    :autoUpload="fileUploadOptions.autoUpload"
                    @uploadCompleteOSS="uploadCompleteOSS2">
                    <div class="item">
                      <div style="margin-bottom:13px" v-if="file_2 || fileCheck">
                        <i v-if="file_2" class="el-icon-success" style="color:#48d17b;font-size: 24px;"></i>
                        <i v-if="fileCheck&&!file_2" class="el-icon-error" style="color:#FA5151;font-size: 24px;"></i>
                      </div>
                      <div>
                        <i class="el-icon-upload2"></i>
                        <span>选择上传法人身份证复印件（须盖章）</span>
                      </div>
                    </div>
                </fileUpload>
                <fileUpload
                    :accept="fileUploadOptions.accept"
                    :limitSize="fileUploadOptions.limitSize"
                    :autoUpload="fileUploadOptions.autoUpload"
                    @uploadCompleteOSS="uploadCompleteOSS3">
                    <div class="item">
                      <div style="margin-bottom:13px" v-if="file_3 || fileCheck">
                        <i v-if="file_3" class="el-icon-success" style="color:#48d17b;font-size: 24px;"></i>
                        <i v-if="fileCheck&&!file_3" class="el-icon-error" style="color:#FA5151;font-size: 24px;"></i>
                      </div>
                      <div>
                        <i class="el-icon-upload2"></i>
                        <span>选择上传营业执照（须盖章）</span>
                      </div>
                    </div>
                </fileUpload>
            </div>
            <div class="btnBox">
              <el-button type="primary" size="small" @click="handleUpLoad" :loading="upLoading">确认提交</el-button>
            </div>
            <p class="ticps">支持{{ fileUploadOptions.accept }}等格式文件，文件大小需小于10M</p>
            <div class="zhu">注：须上传以上三种材料，缺一不可</div>
        </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="300px">
      <div>
        <div class="openTitle">
          暂无企业信息, 请到控制台-企业设置 完善您的公司信息
        </div>
        <div class="tc open">
          完善企业信息<i class="el-icon-right cursor" @click="toConsole"></i>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisible1" width="350px">
      <div>
        <div class="openTitle">您不是企业管理员，请联系企业管理员申请入会</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import fileUpload from "@/baseComponents/fileUpload";
import { checkBasicInfo } from "~dlc/utils";
export default {
  components:{
    fileUpload
  },
  data() {
    return {
      isActive: 1,
      payVisible: false,
      dialogVisible: false,
      dialogVisible1:false,
      dialogTableVisible:false,
      ruhui_lc:[
        {
          tit:"1、提交申请",
          msg:"下载入会申请表，如实并完整填写所有栏位内容后加盖公章； 营业执照复印件，法人身份证正方面复印件加盖公章； 所有文件扫描或拍照上传",
        },
        {
          tit:"2、内部审核",
          msg:"月度理事会上审核入会申请，若审核不通过，协会秘书处会单独通知",
        },
        {
          tit:"3、实地走访",
          msg:"对于审核通过的企业， 秘书处预约走访。走访后在会员群公示。走访和公示环节不能通过的企业秘书处单独通知",
        },
        {
          tit:"4、缴纳会费",
          msg:"按照协会收费标准缴纳会费",
        },
        {
          tit:"5、颁发证书",
          msg:"",
        },
      ],
      fileUploadOptions: {
        limitSize:20,
        accept:".xls,.docx,.word,.pdf,.ppt,.txt,.wps,.jpg,.png",
        autoUpload:true
      },
      file_1:"",
      file_2:"",
      file_3:"",
      fileCheck:false,
      upSuccess:false,
      upLoading:false,
    };
  },
  created() {

  },
  methods: {
    toConsole() {
      this.$router.push({path:"/console/company/basic"})
    },
    // 点击上传附件按钮
    async handleOpen(){
       if(!this.USER_INFO){
        this.$router.push({
          path:"/sign"
        })
        return
      }
      if (!this.USER_INFO.company_bind_status) {
        this.dialogVisible = true;
        return
      }
      if(!this.USER_INFO.is_admin){
        this.dialogVisible1 = true;
        return
      }
      this.upSuccess=false
      let msg=await this.$store.dispatch('API_position/company_vip_Detail',{company_id:this.USER_INFO.company_id})
      if(msg.data.length){ // 提交过一次
        //audit_status审核状态：0-未审核，1-审核通过，2-审核拒绝
        // 审核中
        if(msg.data[0].audit_status==0||msg.data[1].audit_status==0||msg.data[2].audit_status==0){
            this.$message.info('入会信息正在审核')
            return
        }
        //2-审核拒绝
        if(msg.data[0].audit_status==2||msg.data[1].audit_status==2||msg.data[2].audit_status==2){
            this.fileCheck=true
            this.file_1=msg.data[0].url
            this.file_2=msg.data[1].url
            this.file_3=msg.data[2].url

            if(msg.data[0].audit_status==2){
                this.file_1=""
            }
            if(msg.data[1].audit_status==2){
                this.file_2=""
            }
            if(msg.data[2].audit_status==2){
                this.file_3=""
            }
            this.dialogTableVisible=true
            return
        }
        // 审核通过
        if(msg.data[0].audit_status==1&&msg.data[1].audit_status==1&&msg.data[2].audit_status==1){
            this.$message.success('您的入会申请已通过，请勿重复提交')
        }
      }else{  // 第一次提交
        this.dialogTableVisible=true
      }
    },
    // 提交入会申请
    async handleUpLoad(){
      this.fileCheck=true
      if(this.file_1==""){
        this.$message.warning("请上传入会申请表")
        return
      }
      if(this.file_2==""){
        this.$message.warning("请上传法人身份证复印件")
        return
      }
      if(this.file_3==""){
        this.$message.warning("请上传营业执照")
        return
      }
      this.upLoading=true
      //company_vip
      let arr=[
            {"file_name":"111", "url":this.file_1, "file_type":"333", "attachment_type":"1", "audit_status":"0"},
            {"file_name":"111", "url":this.file_2, "file_type":"333", "attachment_type":"2", "audit_status":"0"},
            {"file_name":"111", "url":this.file_3, "file_type":"333", "attachment_type":"3", "audit_status":"0"},
      ]
      let params={
        company_id:this.USER_INFO.company_id,
        attachment_list:JSON.stringify(arr),
        source:this.PJSource,
        apply_from:2
      }
      let res=await this.$store.dispatch('API_position/company_vip',params)
      if(res&&res.success){
        this.upLoading=false
        this.dialogTableVisible=false
        this.upSuccess=true
      }

    },
    uploadCompleteOSS1(e){
      this.file_1=e.url
    },
    uploadCompleteOSS2(e){
      this.file_2=e.url
    },
    uploadCompleteOSS3(e){
      this.file_3=e.url
    },
  },
};
</script>
<style lang="less" scoped>
  .big_title{
    text-align: center;
    font-size: 21px;
    font-weight: 400;
    color: #1F292E;
    line-height: 29px;
  }
  .box1{
    display: flex;
    align-items: center;
    justify-content: center;
    .left_btn{
      width: 173px;
      height: 40px;
      background: linear-gradient(180deg, #FAD961 0%, #F76B1C 100%);
      border-radius: 20px;
      a{
          font-size: 16px;
          font-weight: 500;
          color: #FFFFFF;
          text-align: center;
          line-height: 40px;
          cursor: pointer;
      }
    }
    .right_btn{
      width: 132px;
      height: 40px;
      background: linear-gradient(180deg, #97AEFF 0%, #2E5CFB 100%);
      border-radius: 20px;
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
    }
  }
  .lc_item{
    text-align: center;
    padding-bottom:20px;
    span{
      font-size:16px;
      font-weight: 400;
    }
    p{
      font-size: 14px;
    }
  }
  .footerBox{
    display: flex;
    justify-content: center;
    .big_title{
      text-align: left;
      margin-bottom: 12px;
    }
    div{
      text-align: left;
      width: 300px;
      p{
        font-size: 14px;
        height: 32px;
        font-weight: 400;
        color: #1F292E;
        line-height: 32px;
      }
    }
  }
  /deep/ .el-dialog__header{
    text-align: left;
  }
  /deep/ .el-dialog__body{
    padding: 20px;
  }
  .dialogConten{
    height: auto;
    .sts_success{
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #1F292E;
      line-height: 20px;
      padding-bottom:23px;
    }
    .sts_error{
      height: 20px;
      font-size: 14px;
      font-weight: bold;
      color: #FA5151;
      line-height: 20px;
      padding-bottom:23px;
    }
    .upLoadBox{
      display: flex;
      justify-content: space-around;
      .item{
        width: 271px;
        height: 97px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px dashed #DCDFE6;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-image: url('https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/bg_zhnag.png');
        background-repeat:no-repeat;
        background-position: right;
        i{
          margin-right: 6px;
          color: #909399;
        }
        span{
          font-size: 14px;
          font-weight: 400;
          color: #909399;
          line-height: 14px;
        }
      }
    }
    .btnBox{
      text-align: center;
      padding-top:30px;
      padding-bottom: 20px;
    }
    .ticps{
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #909399;
      line-height: 20px;
    }
    .zhu{
      padding-top: 10px;
      padding-bottom: 30px;
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #FF5155;
      line-height: 20px;
    }
  }
  .open {
    margin: 20px 0 0;
    i {
      color: #049DED;
      margin-left: 5px;
      border: 1px solid #049DED;
      border-radius: 50%;
    }
  }
</style>
